import { graphql } from 'gatsby'
import React from 'react'
import ProcessSections from '../../../utils/processSections'

export default function PlateausPage({ data }) {
  const { pageContent } = data
  return (
    <div className="content-width">
      <div className="title">
        <h1>{pageContent.title}</h1>
      </div>
      <ProcessSections sections={pageContent} />
    </div>
  )
}

export const query = graphql`
  query MyQuery {
    pageContent: sanityPageContent(
      location: { eq: "/history/scoresheets/plateaus" }
    ) {
      id
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      title
      displayTitle
    }
  }
`
